import React from 'react';
import { Link } from 'gatsby';
import { Typography } from 'amphitheatre';
import { useInView } from 'react-intersection-observer';
import styled, { keyframes } from 'styled-components';
import { BsArrowRight } from 'react-icons/bs';

import { Anchor, Icon } from './Styled';

const { Header } = Typography;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  header: string;
  linkText: string;
  to: string;
  external?: boolean;
}

const animFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.black};
  box-sizing: border-box;

  padding: 6rem 1rem;

  .link {
    transform: scale(0);
    opacity: 0;
  }

  .header {
    opacity: 0;
    transform: scale(0);
  }

  &.active {
    .link {
      animation: ${animFadeIn} 1s 0.75s 1 forwards;
    }
    .header {
      animation: ${animFadeIn} 1s 1 forwards;
    }
  }
`;

/**
 *
 * @param {string} header
 * @param {string} linkText The text for the link
 * @param {string} to Where it should link to
 */
export function FadeIn({ header, linkText, to, external }: Props): JSX.Element {
  const [ref, inView] = useInView({ triggerOnce: true });

  const linkTextElm = (
    <>
      {linkText}
      <Icon>
        <BsArrowRight />
      </Icon>
    </>
  );

  let linkElm = (
    <Link className="link" to={to}>
      {linkTextElm}
    </Link>
  );

  if (external) {
    linkElm = (
      <a href={to} className="link" rel="noopener noreferrer" target="_blank">
        {linkTextElm}
      </a>
    );
  }

  return (
    <Wrapper className={inView ? 'active' : 'inactive'} ref={ref}>
      <Header className="header" style={{ textAlign: 'center' }}>
        {header}
      </Header>
      <Anchor
        style={{
          textAlign: 'center',
        }}
      >
        {linkElm}
      </Anchor>
    </Wrapper>
  );
}
