import React from 'react';
import { Link } from 'gatsby';
import { Typography } from 'amphitheatre';
import { useInView } from 'react-intersection-observer';
import styled, { keyframes } from 'styled-components';
import { BsArrowRight } from 'react-icons/bs';

import { Anchor, Icon } from './Styled';

const { Header } = Typography;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  header: string;
  linkText: string;
  to: string;
  external?: boolean;
}

const animUp = keyframes`
  0% {
    transform: translateY(64px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.black};
  box-sizing: border-box;

  padding: 2rem 1rem;

  .link {
    transform: translateY(64px);
  }

  .word {
    display: inline-block;
    transform: translateY(64px);
  }

  &.active {
    .link {
      animation: ${animUp} 1s 1 forwards;
    }
    .word {
      animation: ${animUp} 1s 1 forwards;
    }
  }

  @media only screen and (min-width: 768px) {
    padding: 4rem 1rem;
  }

  @media only screen and (min-width: 1024px) {
    padding: 6rem 1rem;
  }
`;

export default function ({
  header,
  linkText,
  to,
  external,
}: Props): JSX.Element {
  const [ref, inView] = useInView({ triggerOnce: true });

  const delay = 0.1;
  const words = header.split(' ');
  const wrappedWords = words.map((word, index) => (
    <React.Fragment key={`word-${index}`}>
      {index ? ' ' : null}
      <span
        className={`word word-${index}`}
        style={{ animationDelay: `${(index + 1) * delay}s` }}
      >
        {word}
      </span>
    </React.Fragment>
  ));
  const linkDelay = (words.length + 1) * delay + 0.5;

  const linkTextElm = (
    <>
      {linkText}
      <Icon>
        <BsArrowRight />
      </Icon>
    </>
  );

  let linkElm = (
    <Link className="link" style={{ animationDelay: `${linkDelay}s` }} to={to}>
      {linkTextElm}
    </Link>
  );

  if (external) {
    linkElm = (
      <a
        href={to}
        className="link"
        style={{ animationDelay: `${linkDelay}s` }}
        rel="noopener noreferrer"
        target="_blank"
      >
        {linkTextElm}
      </a>
    );
  }

  return (
    <Wrapper className={inView ? 'active' : 'inactive'} ref={ref}>
      <Header
        style={{ overflow: 'hidden', padding: '0.25rem', textAlign: 'center' }}
      >
        {wrappedWords}
      </Header>
      <Anchor
        style={{
          overflow: 'hidden',
          padding: '0.25rem',
          textAlign: 'center',
        }}
      >
        {linkElm}
      </Anchor>
    </Wrapper>
  );
}
