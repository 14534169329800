import styled, { keyframes } from 'styled-components';

const arrowAnim = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(24px);
  }

  100% {
    transform: translateX(0);
  }
`;

export const Icon = styled.div`
  color: ${({ theme }) => theme.primary500};
  display: flex;
  align-items: baseline;
  margin-left: 0.5rem;
`;

export const Anchor = styled.div`
  a {
    color: ${({ theme }) => theme.primary500};
    display: inline-flex;
    align-items: center;

    font-family: ${({ theme }) => theme.fontHeader};
    font-size: 1rem;
    font-weight: normal;

    text-decoration: none;
  }

  &:hover {
    ${Icon} {
      animation: ${arrowAnim} 1.5s ease-in-out infinite;
    }
  }

  @media only screen and (min-width: 1024px) {
    a {
      font-size: 1.5rem;
    }
  }
`;
